import React from "react";
import { socket } from "../../socket";
import { Button, Space } from "antd";

export function ConnectionManager() {
  function connect() {
    socket.connect();
  }

  function disconnect() {
    socket.disconnect();
  }

  return (
    <Space>
      <Button type="primary" onClick={connect} style={{backgroundColor:"#13A9A9"}}>
        Conectar
      </Button>
      <Button type="primary" onClick={disconnect} style={{backgroundColor:"#FF0000"}}>
        Desconectar
      </Button>
    </Space>
  );
}
