import React from "react";
import ReactEcharts from "echarts-for-react";
import { Card } from "antd";

export function Temperature({ number }) {
  const option = {
    series: [
      {
        type: "gauge",
        center: ["50%", "60%"],
        startAngle: 200,
        endAngle: -20,
        min: 0,
        max: 50,
        splitNumber: 10,
        itemStyle: {
          color: "#1fad96",
        },
        progress: {
          show: true,
          width: 30,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            width: 30,
          },
        },
        axisTick: {
          distance: -45,
          splitNumber: 5,
          lineStyle: {
            width: 2,
            color: "#999",
          },
        },
        splitLine: {
          distance: -52,
          length: 14,
          lineStyle: {
            width: 3,
            color: "#999",
          },
        },
        axisLabel: {
          distance: -20,
          color: "#999",
          fontSize: 20,
        },
        anchor: {
          show: false,
        },
        title: {
          show: false,
        },
        detail: {
          valueAnimation: true,
          width: "60%",
          lineHeight: 40,
          borderRadius: 8,
          offsetCenter: [0, "-15%"],
          fontSize: 35,
          fontWeight: "bolder",
          formatter: "{value} °C",
          color: "inherit",
        },
        data: [
          {
            value: number,
          },
        ],
      },
      {
        type: "gauge",
        center: ["50%", "60%"],
        startAngle: 200,
        endAngle: -20,
        min: 0,
        max: 50,
        itemStyle: {
          color: "#184a42",
        },
        progress: {
          show: true,
          width: 6,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        detail: {
          show: false,
        },
        data: [
          {
            value: number,
          },
        ],
      },
    ],
  };

  return (
    <Card title="Temperatura" style={{ width: "30em",background: "#F5F5F5", }}>
      <ReactEcharts option={option} />
    </Card>
  );
}
