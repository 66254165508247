import React from "react";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import { Card } from "antd";

export function Altitude({ data, labels }) {
  const option = {
    tooltip: {
      trigger: "axis",
      position: function (pt) {
        return [pt[0], "10%"];
      },
    },
    title: {
      left: "center",
      text: "",
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: "none",
        },
        restore: {},
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: labels,
    },
    yAxis: {
      type: "value",
      boundaryGap: [0, "100%"],
    },
    dataZoom: [
      {
        type: "inside",
        start: 0,
        end: 10,
      },
      {
        start: 0,
        end: 10,
      },
    ],
    series: [
      {
        name: "Medida",
        type: "line",
        symbol: "M",
        sampling: "lttb",
        itemStyle: {
          color: "#184a42",
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#1fad96",
            },
            {
              offset: 1,
              color: "#184a42",
            },
          ]),
        },
        data: data,
      },
    ],
  };
  return (
    <Card title="Altitud vs tiempo" bordered={true} style={{ maxWidth: "70em", minWidth:"39em", background: "#F5F5F5", }}>
      <ReactEcharts option={option} />
    </Card>
  );
}
