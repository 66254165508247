import React from "react";
import ReactEcharts from "echarts-for-react";
import { Card } from "antd";

export function Barometro({ number }) {
  const option = {
    series: [
      {
        type: "gauge",
        min: 0,
        max: 1000,
        splitNumber: 10,
        radius: "80%",
        axisLine: {
          lineStyle: {
            color: [[1, "#1fad96"]],
            width: 3,
          },
        },
        splitLine: {
          distance: -18,
          length: 18,
          lineStyle: {
            color: "#1fad96",
          },
        },
        axisTick: {
          distance: -12,
          length: 10,
          lineStyle: {
            color: "#1fad96",
          },
        },
        axisLabel: {
          distance: -50,
          color: "#1fad96",
          fontSize: 23,
        },
        anchor: {
          show: true,
          size: 20,
          itemStyle: {
            borderColor: "#000",
            borderWidth: 2,
          },
        },
        pointer: {
          offsetCenter: [0, "10%"],
          icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
          length: "115%",
          itemStyle: {
            color: "#000",
          },
        },
        detail: {
          valueAnimation: true,
          precision: 1,
        },
        title: {
          offsetCenter: [0, "-50%"],
        },
        data: [
          {
            value: number,
            name: "hPa",
          },
        ],
      },
      {
        type: "gauge",
        min: 0,
        max: 600,
        splitNumber: 6,
        axisLine: {
          lineStyle: {
            color: [[1, "#000"]],
            width: 3,
          },
        },
        splitLine: {
          distance: -3,
          length: 12,
          lineStyle: {
            color: "#000",
          },
        },
        axisTick: {
          distance: 0,
          length: 10,
          lineStyle: {
            color: "#000",
          },
        },
        axisLabel: {
          distance: 10,
          fontSize: 18,
          color: "#000",
        },
        pointer: {
          show: false,
        },
        title: {
          show: false,
        },
        anchor: {
          show: true,
          size: 14,
          itemStyle: {
            color: "#000",
          },
        },
      },
    ],
  };

  return (
    <Card title="Presion atmosferica" bordered={true} style={{ width: "30em", background: "#F5F5F5", }}>
      <ReactEcharts option={option} />
    </Card>
  );
}
