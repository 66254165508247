import React, { useState, useEffect } from "react";
import { Layout, theme, Space } from "antd";

//import './App.css';
import { socket } from "./socket";
import { ConnectionState } from "./components/connectionManager/ConnectionState";

//import { Events } from "./components/Events";
//import { MyForm } from "./components/MyForm";
import { Temperature } from "./components/temperature/Temperature";
import { Barometro } from "./components/barometro/Barometro";
import { Line } from "./components/line/Line";
import { Altitude } from "./components/altitude/Altitude";

const { Header, Content, Footer } = Layout;

const App = () => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  //const [fooEvents, setFooEvents] = useState([]);
  const [measureTemperature, setmeasureTemperature] = useState(0);
  const [measurePA, setmeasurePA] = useState(0);
  const [altitudes, setAltitudes] = useState([]);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    //    function onFooEvent(value) {
    //      console.log(value);
    //      setFooEvents((previous) => [...previous, value]);
    //    }

    function onSensorEvent(value) {
      //console.log(value);
      let midate = new Date(value.date);
      let msg = `${midate.getDate()}/${
        midate.getMonth() + 1
      }/${midate.getFullYear()}/${midate.getHours()}:${midate.getMinutes()}:${midate.getSeconds()}`;
      console.log(msg);
      setmeasureTemperature(value.temperature);
      setmeasurePA(value.pa);
      setAltitudes((previous) => [...previous, value.altitude]);
      setDates((previous) => [...previous, msg]);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    //    socket.on("foo", onFooEvent);
    socket.on("sensor", onSensorEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      //      socket.off("foo", onFooEvent);
      socket.off("sensor", onSensorEvent);
    };
  }, []);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          minWidth: "60em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="demo-logo" />
        <h3 style={{ color: "#FFFFFF" }}>Monitor de sensores</h3>
      </Header>
      <Content
        style={{
          padding: "0 2% 0 2%",
          width: "100%",
          minWidth: "60em",
          background: "#F5F5F5",
        }}
      >
        <div
          style={{
            padding: 24,

            width: "100%",
            background: colorBgContainer,

            borderRadius: borderRadiusLG,
          }}
        >
          <Space wrap="effective" size="middle" style={{ display: "flex" }}>
            <ConnectionState isConnected={isConnected} />
            <Temperature number={measureTemperature} />
            <Barometro number={measurePA}></Barometro>
            <Altitude data={altitudes} labels={dates}></Altitude>
            <Line data={altitudes} labels={dates}></Line>
          </Space>
        </div>
      </Content>
      <Footer
        style={{
          textAlign: "center",
          width: "100%",
          minWidth: "60em",

          alignItems: "center",
        }}
      >
        APP sensores ©2023 Created by smurillo29
      </Footer>
    </Layout>
  );
};
export default App;
