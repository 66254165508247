import React from "react";
import ReactEcharts from "echarts-for-react";
import { Card } from "antd";

export function Line({ data, labels }) {
  const option = {
    xAxis: {
      type: "category",
      data: labels,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: data,
        type: "line",
      },
    ],
  };

  return (
    <Card title="Altitud vs tiempo" bordered={true} style={{ maxWidth: "70em", minWidth:"39em",background: "#F5F5F5", }}>
      <ReactEcharts option={option} />
    </Card>
  );
}
