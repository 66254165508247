import React from "react";
import "./ConnectionState.css";
import { ConnectionManager } from "./ConnectionManager";
import { Card } from "antd";

export function ConnectionState({ isConnected }) {
  return (
    <Card
      title={<p>Estado de la conexion: {"" + isConnected}</p>}
      extra=""
      style={{
        width: "25em",
        background: "#F5F5F5",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <ConnectionManager></ConnectionManager>
    </Card>
  );
}
